import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import MainBanner from "../components/Index/MainBanner";
import FeaturedService from "../components/Index/FeaturedService";
import AboutUs from "../components/Index/AboutUs";
import OurMission from "../components/Index/OurMission";
import HowItWork from "../components/Index/HowItWork";
import StartProject from "../components/Index/StartProject";
import { graphql } from "gatsby";

const Home = () => {
  return (
    <Layout>

      <Navbar />

      <MainBanner />

      <FeaturedService />

      <AboutUs />

      <OurMission />

      {/*<Funfacts />*/}

      {/*<OurServices />*/}

      <HowItWork />

      {/*<TeamMembers />*/}

      {/*<Testimonials />*/}

      {/*<Partner />*/}

      <StartProject />

      {/*<OurBlog />*/}

      <Footer />

    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
