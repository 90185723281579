import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import mission1 from '../../assets/images/our-mission/our-mission1.png'
import { Link, Trans } from "gatsby-plugin-react-i18next";

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> <Trans i18nKey="index:mission.title">Our mission</Trans>
                                </span>
                                <h2><Trans i18nKey="index:mission.lead">Skup się na marketingu i pozyskiwaniu klientów</Trans></h2>
                                <p><Trans i18nKey="index:mission.descTop">"Samochód do ścigania" się z innymi dostarczymy oraz dostosujemy my</Trans></p>
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list1">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list2">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list3">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list4">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list5">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        <Trans i18nKey="index:mission.list6">Big Data</Trans>
                                        <img src={shape2} alt="banner" />
                                    </li>
                                </ul>
                                <p><Trans i18nKey="index:mission.descBottom">"Samochód do ścigania" się z innymi dostarczymy oraz dostosujemy my</Trans></p>

                                {/*<Link to="/about-us-two" className="default-btn">*/}
                                {/*    <i className="flaticon-right"></i>*/}
                                {/*    Read More*/}
                                {/*    <span></span>*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={mission1} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurMission
