import React from "react";
import start1 from "../../assets/images/project-start1.png";
import shape3 from "../../assets/images/shape/vector-shape3.png";
import { Link, Trans } from "gatsby-plugin-react-i18next";

const StartProject = () => {
  return (
    <section className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={start1} alt="banner" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2><Trans i18nKey="index:start-project.lead">We Like to Start Your Project With Us</Trans></h2>
              <p><Trans i18nKey="index:start-project.desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna.</Trans></p>
              <Link to="/contact" className="default-btn">
                <i className="flaticon-web"></i> <Trans i18nKey="index:start-project.button">Get Started</Trans> <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape3} alt="banner" />
      </div>
    </section>
  );
};

export default StartProject;
