import React from "react";
import { Link } from "gatsby";
import img10 from "../../assets/images/about/about-img10.png";
import shape1 from "../../assets/images/about/about-shape1.png";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import starIcon from "../../assets/images/star-icon.png";
import { Trans } from "gatsby-plugin-react-i18next";

const AboutUs = () => {
  return (
    <div className="about-area pb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-img">
              <img src={img10} alt="banner" />
              <div className="shape">
                <img src={shape1} alt="banner" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    <Trans i18nKey="index:about-us.title">About Us</Trans>
                                </span>
                <h2><Trans i18nKey="index:about-us.lead">Business Analytics For Data-Driven Solutions</Trans></h2>
                <p><Trans i18nKey="index:about-us.descTop">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna.</Trans></p>
                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list1">Big Data</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list2">Data Visualization</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list3">Data Warehousing</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list4">Data Management</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list5">Business Intelligence</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    <Trans i18nKey="index:about-us.list6">Business Intelligence</Trans>
                    <img src={shape2} alt="banner" />
                  </li>
                </ul>
                <p><Trans i18nKey="index:about-us.descBottom">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna.</Trans></p>
                {/*<Link to="/about-us" className="default-btn">*/}
                {/*  <i className="flaticon-right"></i>*/}
                {/*  <Trans i18nKey="index:navbar.aboutUs">About Us</Trans> <span></span>*/}
                {/*</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
